@font-face {
  font-family: 'Raleway';
  src: url('../src/assets/styles/fonts/Inter/Inter.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  // font-family: 'Raleway' !important;
  font-family: 'Raleway' !important;
  padding: 0;
  border: 0;
  margin: 0;
  // overflow-x: hidden !important;

  // #root{
  //   overflow-x: hidden !important;
  // }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  opacity: 0.2 !important;
  background: #F6F6F6 !important;
  // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  opacity: 0.2 !important;
  background: #F6F6F6;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #CECECE;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'Raleway';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: var(--telembro-branco-bgk, #F6F6F6);
  background: #fff;
  color: #011F26;
  font-size: 14px;
  // padding-left: 20px;
  // padding-right: 20px;
  // min-width: 350px;
  // margin-top: 85px;

  // .custom-editor {
  //   .ck-editor__editable {
  //     max-height: 300px; /* Set the maximum height */
  //     overflow-y: auto;  /* Enable vertical scrolling */
  //     border: 1px solid #ccc; /* Optional styling */
  //   }
  // }
  .overflow-div{
    overflow-y: auto
  }

  #modal-root{
    position: relative;
    z-index: 12343654;
  }

  .warning-container2{
    display: flex;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    background: #F2F2F2;

    .warning-container2-title{
      color: var(--dark, #011F26);
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    // .warning-container2-content{

    // }
  }

  .warning-container{
    display: flex;
    width: 328px;
    padding: 16px;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    align-content: center;
    border-radius: 8px;
    background: #FFF6C8;

    &.sucess{
      background: #FFF6C8;
    }

    color: var(--dark, #011F26);
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
  }

  a{
    color: var(--primary, #F25C05);
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: underline;
    
    &:hover{
      color: var(--primary, #F25C05);
    }
  }

  .react-datepicker-wrapper{
    width: 100%;
  }

  .tabs-container{
    width: 100%;

    .tabs-header{
      display: flex;
      justify-content: flex-start;
      height: 65px;
      flex-direction: row;
      align-items: center;
      gap: 9px;

      .tab-button{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 65px;
        flex-direction: row;
        gap: 9px;
        background: #FFF;
        transition: background-color 0.3s;
        padding: 16px;
        border: none;
        color: #A3A3A3;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        &.active{
          display: flex;
          // width: 180px;
          // height: 65px;
          padding: 16px;
          border-bottom: 4px solid var(--primary, #F25C05);
          background: #FFF;
          color: var(--primary, #F25C05);
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .tab-content{
      .no-pending-content{
        padding-top: 9px;
        
        .no-pending-text{
          color: #000;
          text-align: center;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          max-width: 196px;
          margin: auto;
          padding-top: 24px;
        }
      }

      .pending-text{
        color: var(--dark, #011F26);
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-top: 24px;
        min-height: 14px;
      }
    }
  }

  .save-user-profile-container{
    color: var(--primary, #F25C05);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }

  .goback-header-container{
    padding: 16px;
    height: 65px;
    // padding-left: 16px;
    // padding-right: 21px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    z-index: 9;
    width: 100%;
    // max-width: 360px;
    margin: auto;
    // margin-bottom: 22px;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 9px;

    .left-side-container{
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      gap: 9px;

      .backaction-container{
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        border-radius: 50px;
        // border: 1px solid #818181;
        overflow: hidden;
        cursor: pointer;
  
        img{
          width: 50px;
          height: 50px;
          flex-shrink: 0;
          border-radius: 50px;
        }
      }
  
      .title-container{
        overflow: hidden;
        color: #404040;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Raleway;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .right-items-container{
      margin: auto;
      margin-right: 0;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      position: relative;

      .threee-dots-container{
        height: 59px;
        width: 45px;
        display: flex;
        margin-right: -16px;
        margin-top: -10px;
        cursor: pointer;
        
        img{
          margin: auto;
        }

        &.expanded{
          background: #E9E9E9;

        }
      }

      .sub-menu-container{
        position: absolute;
        background: #E9E9E9;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        right: -16px;
        top: 49px;
        z-index: 999;

        .sub-menu-item-container{
          display: flex;
          padding: 16px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          background: #E9E9E9;
          color: var(--primary, #F25C05);
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          cursor: pointer;
        }
      }
    }

    .info-container{
      margin: auto;
      margin-right: 0;
    }
  }

  .form-row-group{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    align-items: center;

    &.wrapable{
      flex-wrap: wrap;
    }

    &.bigger{
      gap: 27px !important;
    }

    .toggle{
      display: flex;
      align-items: center;
    }
  }


  .google-login-button{
    width: 100%;
    
    div{
      width: 100%;
      margin: auto;
      

      // .nsm7Bb-HzV7m-LgbsSe-MJoBVe{
      //   border-radius: 8px;
      //   border: 1px solid #ADADAD;
      //   background: #FFF;
      // }

      // .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
      //   justify-content: space-evenly;
      // }
    }
  }

  .button-container{
    display: flex;
    width: 100%;
    min-height: 56px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    gap: 8px;
    flex-wrap: wrap;

      button{
        display: flex;
        width: 100%;
        height: 56px;
        min-height: 56px;
        padding: 8px 34px;
        justify-content: space-evenly;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        background: #FFF;
        text-align: center;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &.primary{
          background: var(--primary, #F25C05);
          color: #FFF;
          border: none;
        }

        &.small{
          max-width: 156px;
          padding: 8px 8px;
          margin-bottom: 24px;
        }

        &.secondary{
          border: 1px solid var(--primary, #F25C05);
          color: var(--primary, #F25C05);
          text-align: center;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &.danger{
          border: 1px solid var(--primary, #F20505);
          color: var(--primary, #F20505);
          text-align: center;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &.danger-danger{
          border: 1px solid red;
          background: red;
          color: #FFF;
          text-align: center;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
  }

  .basic-header-container{
    padding-top: 18px;
    padding-left: 16px;
    padding-right: 21px;
    padding-bottom: 22px;
    margin-bottom: 43px;
    width: 100%;
    // max-width: 360px;
    background: #FAFAFA;


    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .profilepicture-container{
      width: 50px;
      height: 50px;
      flex-shrink: 0;
      border-radius: 50px;
      // border: 1px solid #818181;
      overflow: hidden;
      cursor: pointer;

      img{
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        border-radius: 50px;
      }
    }

    .logo-container{
      cursor: pointer;

      img{
        width: 105px;
        height: 27px;
        flex-shrink: 0;
      }
    }

    .notifications-container{
      position: relative;
      cursor: pointer;


      .icon-container{
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }

      .notifications-count-container{
        position: absolute;
        color: #FFF;
        text-align: center !important;
        font-family: Raleway;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 8px 11px 10px 8px;
        right: -10px;
        top: -17px;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        border-radius: 50px;
        text-align: center;
        background: var(--primary, #F25C05);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .regular-title{
    color: var(--dark, #011F26);
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    margin-bottom: 16px;

    &.smaller{
      font-size: 16px;
    }
  }
  
  .regular-text{
    color: var(--dark, #011F26);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // margin-bottom: 16px;
    width: 100%;
    
    span{
      color: var(--dark, #E1672A);
    }
  }

  .input-and-icon{
    position: relative;

    .input-icon{
      position: absolute;
      right: 0;
      top: 6px;
    }
  }

  .basic-container{
    padding-top: 22px;
    // padding-left: 16px;
    // padding-right: 21px;
    padding-left: 8px;
    padding-right: 8px;
    max-width: 360px;
    margin: auto;
    background: #FFF;
    position: relative;

    &.bottom-overflow{
      padding-bottom: 190px;
    }

    &.no-padding{
      padding: 0;
      max-width: 100%;
    }

    &.darker{
      background: #FAFAFA;
    }

    .change-photo{
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

        /* ToggleSwitch.css */
    .iphone-toggle-switch {
      display: flex;
      align-items: center;
    }

    .iphone-toggle-switch-checkbox {
      display: none;
    }

    .iphone-toggle-switch-label {
      display: inline-block;
      width: 36px;
      height: 16px;
      position: relative;
      cursor: pointer;
    }

    .iphone-toggle-switch-label .iphone-toggle-switch-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #ccc;
      border-radius: 12px;
      transition: background-color 0.2s;
    }

    .iphone-toggle-switch-label .iphone-toggle-switch-switch {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      background-color: #F25C05;
      border-radius: 50%;
      transition: transform 0.2s;
    }

    .iphone-toggle-switch-checkbox:checked + .iphone-toggle-switch-label .iphone-toggle-switch-inner {
      // background-color: #4cd964; /* Cor verde para o estado ativo */
      background-color: rgba(#F25C05, 0.6); /* Cor verde para o estado ativo */
    }

    .iphone-toggle-switch-checkbox:checked + .iphone-toggle-switch-label .iphone-toggle-switch-switch {
      transform: translateX(20px); /* Move o switch para a direita */
      background-color: #F25C05; /* Cor da faixa quando ON */
    }

    .iphone-toggle-switch-text {
      margin-left: 10px;
      font-size: 16px;
      user-select: none;
    }

    .iphone-add-homescreen{
      width: 100%;
      background: #FFDDBD;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 16px;
      gap: 16px;

      .top-text{
        color: #000;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 9px;
      }

      .bottom-text{
        color: var(--primary, #F25C05);
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
      }
    }

    .appointments-notifications{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      margin-bottom: 16px;
      gap: 1px;

      .appointment-notification{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        width: 100%;
        padding: 12px;
        border-bottom: 1px solid #EEE;
        background: #FFF;
        height: 93px;
        gap: 8px;

        &.new{
          background: rgba(242, 91, 8, 0.15);
          cursor: pointer;
        }

        .notification-title{
          color: var(--dark, #011F26);
          font-family: Raleway;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .notification-observation{
          overflow: hidden;
          color: #8B8B8B;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          max-width: 260px;
        }

        .today-text{
          border-radius: 8px;
          background: var(--primary, #F25C05);

          color: #FFF;
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          display: flex;
          width: 60px;
          height: 24px;
          padding: 4px 12px;
          justify-content: center;
          align-items: flex-start;
          gap: 4px;
          flex-shrink: 0;
        }
      }
    }

    .search-container{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      margin-bottom: 16px;

      input{
        height: 42px !important;
        min-height: 42px !important;
        border-radius: 0px 8px 8px 0px !important;
        border: 1px solid rgba(0, 0, 0, 0.10) !important;
        background: #FFF !important;
        padding: 9px 16px 9px 16px !important;
      }

      .clear-filter{
        position: absolute;
        right: 8px;
        cursor: pointer;
      }
    }

    .search-results-count-container{
      color: var(--dark, #011F26);
      font-family: Raleway;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 16px;
    }

    .image-container{
      width: 100%;
      padding-top: 35px;
      margin :auto;
      text-align: center;
      margin-bottom: 28px;

      img{
        margin :auto;
      }
    }

    .profile-image-container{
      width: 100%;
      padding-top: 2px;
      margin :auto;
      text-align: center;
      margin-bottom: 12px;

      img{
        margin :auto;
        border-radius: 100px;
        width: 100px;
        height: 100px;
        flex-shrink: 0;
      }
    }

    .image-container{
      width: 100%;
      padding-top: 2px;
      margin :auto;
      text-align: center;
      margin-bottom: 12px;

      img{
        margin :auto;
        flex-shrink: 0;
      }
    }

    .no-content-text{
      color: #000;
      text-align: center;
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      width: 100%;
      max-width: 196px;
      margin :auto;
      margin-bottom: 64px;
    }

    .no-appointments{
      color: #888;
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      min-height: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 14px;
      width: 100%;
      max-width: 196px;
      margin :auto;
      margin-bottom: 64px;
      text-align: center;

      a{
        text-decoration: none;
        font-weight: 600;
      }
    }

    .cards-container{
      margin-bottom: 72px;
      display: flex;
      flex-direction: column;
      // gap: 16px;

      &.notification{
        align-items: center;
        padding-top: 24px;

        .pending-text{
          width: 100%;
          max-width: 328px;
          text-align: left;
          padding-bottom: 24px;
          color: var(--dark, #011F26);
          font-family: Raleway;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .actions{
        width: 100%;
        padding: 16px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 16px;
        border-bottom: 1px solid #EEE;
        background: #FFF;

        .confirm{
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          gap: 9px;
          color: #0F9803;
          font-family: Raleway;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          min-width: 50%;
          cursor: pointer;
          border-right: 1px solid rgba(0, 0, 0, 0.10);;
        }

        .refuse{
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          gap: 9px;
          color: #FF0000;
          font-family: Raleway;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          min-width: 50%;
          cursor: pointer;
        }
      }

      
      &.single-one{
        // position: absolute;
        // top: 0;
        margin-bottom: 0 !important;
      }

      .appointmentgroup-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        gap: 16px;

        .appointmentgroup-date{
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          gap: 16px;
        }

        color: #000;
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 16px;

        .appointmentgroup-options{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          align-self: stretch;
          gap: 8px;

          .appointmentgroup-option{
            cursor: pointer;
            // img{

            // }
          }
        }
      }

      .card{
        border-radius: 12px;
        background: #FFF;
        /* Sombra_card */
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
        width: 100%;
        max-width: 328px;
        height: 123px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border: none;
        cursor: pointer;
        margin-bottom: 16px;

        &.single{
          max-width: unset;
          border-radius: 0;
          height: auto;
          margin-bottom: 0;

          .see-more-link{
            color: var(--telembro-laranja, #F25C05);
  
            /* TL-modulo-privacidade-texto */
            font-family: Raleway;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 166.667% */
            letter-spacing: -0.24px;
            text-decoration: none;
            min-height: 20px;
            position: relative;
            background: #FFF;
            margin-top: -36px;

            &.expand{
              margin-top: -16px;
            }
          }


          .participants{
            color: var(--dark, #011F26);
            font-family: Raleway;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            width: 100%;

            .participants-list{
              display: flex;
              align-items: flex-start;
              gap: 4px;
              flex-wrap: wrap;
              margin-top: 9px;

              .participant{
                display: flex;
                height: 24px;
                padding: 4px 8px;
                justify-content: center;
                align-items: flex-start;
                gap: 4px;
                border-radius: 8px;
                background: #F2F2F2;
              }
            }

            .barcode-number-container{
                display: flex;
                justify-content: space-between;
                align-items: center;

                .barcode-number{
                  color: rgba(139, 139, 139, 0.72);
                  font-family: Raleway;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
            }
          }
        }
        
        button{
          background: transparent;
          border: none;
          /* margin-left: 8px; */
          margin-right: 8px;
          margin-left: 8px;
          cursor: pointer;
        }
      
        .top-part {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          gap: 16px;

          .left{
            .appointment-date{
              border-radius: 8px;
              background: #F2F2F2;
              display: flex;
              width: 100%;
              // max-width: 151px;
              min-height: 24px;
              padding: 4px 12px;
              justify-content: center;
              align-items: flex-start;
              gap: 4px;
              color: #404040;
              font-family: Raleway;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
      
              img{
                margin-right: 5px;
              }
            }
          }

          .right{
            .appointment-type{
              border-radius: 8px;
              // background: rgba(151, 71, 255, 0.40);
              display: flex;
              width: 100%;
              max-width: 180px;
              min-height: 24px;
              padding: 4px 8px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              color: var(--white, #FFF);
              font-family: Raleway;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
      
              img{
                margin-right: 5px;
              }


              &.8153E4 {
                background: #8153E4;
              }
              
              &.536AE4 {
                  background: #536AE4;
              }
              
              &.43904F {
                  background: #43904F;
              }
              
              &.904343 {
                  background: #904343;
              }
              
              &.745403 {
                  background: #745403;
              }
              
              &.B092EE {
                  background: #B092EE;
              }
              
              &.8998E9 {
                  background: #8998E9;
              }
              
              &.8AB390 {
                  background: #8AB390;
              }
              
              &.C08F8F {
                  background: #C08F8F;
              }
              
              &.BC983F {
                  background: #BC983F;
              }
              
              &.CBBAEE {
                  background: #CBBAEE;
              }
              
              &.B3BFFC {
                  background: #B3BFFC;
              }
              
              &.AFC8B3 {
                  background: #AFC8B3;
              }
              
              &.D2B7B7 {
                  background: #D2B7B7;
              }
              
              &.F9DA8D {
                  background: #F9DA8D;
              }
              
              /* Colors2 Array */
              &.E469B3 {
                  background: #E469B3;
              }
              
              &.3FA2C6 {
                  background: #3FA2C6;
              }
              
              &.76C905 {
                  background: #76C905;
              }
              
              &.E65252 {
                  background: #E65252;
              }
              
              &.3C464F {
                  background: #3C464F;
              }
              
              &.FFA9DD {
                  background: #FFA9DD;
              }
              
              &.60BBDC {
                  background: #60BBDC;
              }
              
              &.90C843 {
                  background: #90C843;
              }
              
              &.F19898 {
                  background: #F19898;
              }
              
              &.4F5C68 {
                  background: #4F5C68;
              }
              
              &.FFCDEB {
                  background: #FFCDEB;
              }
              
              &.8AD8F4 {
                  background: #8AD8F4;
              }
              
              &.B2E073 {
                  background: #B2E073;
              }
              
              &.F2BEBE {
                  background: #F2BEBE;
              }
              
              &.8190A1 {
                  background: #8190A1;
              }
              
            }
          }
        }

        .bottom-part{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          
          .title{
            color: var(--dark, #011F26);
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: normal;
            height: 16px;
            width: 100%;
            max-width: 280px;
            overflow-x: hidden;
          }

          .observations{
            overflow: hidden;
            color: #8B8B8B;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: Raleway;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 300px;
            height: 32px;

            &.expand{
              height: 100%;
              text-overflow: unset;
              white-space: unset;
            }
          }
        }
      }
    }

    .invitation-alert-container{
      margin: auto;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      padding-right: 21px;
      border-radius: 12px;
      background: #FFE6D7;
      width: 100%;
      max-width: 328px;
      height: auto;
      min-height: 65px;
      flex-shrink: 0;
      gap: 10px;
      margin-bottom: 18px;

      // .invitation-alert-icon{
      // }

      .invitation-alert-text{
        display: flex;
        flex-direction: column;
        gap: 4px;

        .invitation-alert-top{
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: center;
          flex-wrap: wrap;

          color: var(--dark, #011F26);
          font-family: Raleway;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          span{
            color: var(--primary, #F25C05);
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top: -3px;
          }
        }

        .invitation-alert-bottom{
          a{
            color: var(--primary, #F25C05);
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration: none;
          }
        }
      }
    }

    .regular-text-container{
      display: flex;
      flex-direction: column;
      gap: 4px;

      display: flex;
      flex-direction: row;
      // gap: 4px;
      align-items: center;
      flex-wrap: wrap;

      &.not-wrapable{
        flex-wrap: unset;
        white-space: nowrap;
      }

      color: var(--dark, #011F26);
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 10px;


      span{
        color: var(--primary, #F25C05);
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-right: -3px;
      }
    }

    .bottom-menu{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;

      .bottom-menu-container{
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        align-items: center;
        background: #FFF;
        box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05);
        width: 100%;
        // max-width: 360px;
        max-width: 650px;
        margin: auto;
        z-index: 999;
        padding-bottom: 10px;
  
        .bottom-menu-item-container{
          padding: 12px 10px;
          background: #FFF;
          display: flex;
          align-items: center;
          flex-direction: column;
          text-align: center;
          color: #404040;
          text-align: center;
          font-family: Raleway;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          cursor: pointer;
  
  
          &.active{
            color: var(--primary, #F25C05);
            text-align: center;
            font-family: Raleway;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
  
          &.middle{
            padding: 0;
            position: relative;
            width: 62px;
  
            button{
              position: absolute;
              display: flex;
              width: 56px;
              height: 56px;
              padding: 16px;
              align-items: center;
              gap: 8px;
              flex-shrink: 0;
              border-radius: 28px;
              background: var(--primary, #F25C05);
              border: none;
              top: -45px;
              // left: -30px;
              // left: -10px;
              left: 0px;
              z-index: 5;
            }
          }
        }
      }
    }

    .appointment-cards-container{
      display: flex;
      // justify-content: flex-start;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      max-width: 360px;
      // padding: 7px 12px;
      // border-radius: 12px;
      // background: #FFF;
      gap: 8px;
      margin-bottom: 16px;
      padding-bottom: 90px;
    }

    .appointment-card-container{
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      padding: 7px 12px;
      border-radius: 12px;
      background: #FFF;
      gap: 8px;
      margin-bottom: 16px;
      height: 143px;
      // max-height: 104px;
      flex: 1;
      cursor: pointer;

      &.small{
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        // max-width: 157px;
        min-width: 157px;
        margin-bottom: 0;
        height: 104px;
      }
      
      /* Sombra_card */
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);

      .date-text-container{
        display: flex;
        flex-direction: column;
        gap: 1px;

        &.small{
          flex-direction: row;
          align-items: center;
          gap: 4px;
        }

        .day-container{
          color: var(--primary, #F25C05);
          text-align: center;
          font-family: Raleway;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          &.small{
            font-size: 12px;
          }
        }
        .month-container{
          color: #A2A2A2;
          text-align: center;
          font-family: Raleway;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          &.small{
            font-size: 10px;
          }
        }
      }

      .appointments-list-container{
        display: flex;
        flex-direction: column;
        gap: 1px;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        .no-appointments{
          color: #888;
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          min-height: 20px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          gap: 5px;
          margin: auto;

          a{
            text-decoration: none;
          }
        }

        .appointment-container{
          color: #888;
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-top: 7px;
          padding-bottom: 8px;
          padding-left: 10px;
          padding-right: 10px;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
          overflow-y: hidden;

          &:not(:last-child){
            border-bottom: 1px solid #EEE;
          }

          &.next{
            border-bottom: none;
            position: relative;
            padding-right: 46px;

            &.small{
              padding-right: 0px;
              border-bottom: 1px solid #EEE;

              a{
                background: #FFF;
                margin-left: -42px;
                padding-left: 8px;

                &.small{
                  position: absolute;
                  right: 0;
                }
              }
            }

            a{
              margin-left: 8px;
              &.small{
                position: absolute;
                right: 0;
              }
            }
          }
        }
      }
    }

  }

  .messages-container{
    padding-top: 24px;
    background: #f2f2f2;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 100px;
    padding-left: 17px;
    padding-right: 18px;
    scroll-behavior: smooth;
    background: url("../src/assets/images/background.svg"), lightgray 50% / cover no-repeat !important;

    display: flex;
    gap: 14px;
    flex-direction: column;

    .messages-container-title{
      color: var(--dark, #011F26);
      font-family: Raleway;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .conversation-bottom-ref{
      position: fixed;
      bottom: 0;
    }

    .message-card-container{
      border-radius: 8px 8px 8px 0px;
      background: #FFF;
      box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
      padding-top: 13px;
      color: var(--dark, #011F26);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 80%;
      margin-left: 0;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      gap: 56px;
      
      &.mine{
        margin-right: 0;
        margin-left: auto;
        border-radius: 8px 8px 0px 8px;
        background: #FFCAAD;
      }
    
      & + &{
        margin-top: 56px;
      }
      
      button{
        background: transparent;
        border: none;
        /* margin-left: 8px; */
        margin-right: 8px;
        margin-left: 8px;
        cursor: pointer;
      }
    
      .info {
        padding-left: 13px;
        padding-right: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        .text{
          width: 100%;
          padding-bottom: 12px;
    
          .sender-name{
            color: #888;
            font-family: Raleway;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 5px;
          }
    
          .message-text{
            margin-bottom: 4px;
            padding-right: 24px;
    
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 100%;
            width: 100%;
          }
    
          .message-time{
            color: #979797;
            text-align: right;
            font-family: Raleway;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
    
          .appointment-name {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            font-size: 16px;
            width: 245px;
            
            strong{
              inline-size: 245px; 
              overflow-wrap: break-word;
              height: auto;
            }
    
            small {
              /* background: ${({ theme }) => theme.colors.primary.lighter};
              color: ${({ theme }) => theme.colors.primary.main}; */
              /* text-transform: uppercase; */
              padding: 4px;
              border-radius: 4px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .fab-container{
    position: fixed;
    bottom: 16px;
    left: 0;
    width: 100%;

    .fab-content{
      display:flex;
      justify-content:flex-start;
      gap: 8px;
      align-items: flex-start;
      width: 100%;
      padding-top: 12px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 4px;
      text-align: center;
      background: #FFF;
      box-shadow: 0px -4px 2px 0px rgba(0, 0, 0, 0.05);
      min-height: 78px;
      max-height: 111px;
      max-width: 360px;
      margin: auto;
  
      .text-part{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
  
        textarea{
          border-radius: 8px;
          border: 1px solid rgba(1, 31, 38, 0.20);
          background: #FFF;
          height: 42px;
          padding-top: 0;
          color: #CACACA;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-top: 13px;
          padding-left: 12px;
          height: 42px !important;
        }
  
        .counter{
          display: flex;
          justify-content: flex-end;
          color: rgba(170, 170, 170, 0.67);
          font-family: Raleway;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
  
        }
      }
      
  
      button{
        background: transparent;
        border: none;
        // margin: auto;
        width: 44px;
        height: 42px;
        padding: 0;
        
        img{
          height: 42px;
        }
      }
  
      .iconbutton{
        text-align: center;
        /* width:50px;
        height:50px;
        border-radius: 100%;
        background: ${({ theme }) => theme.colors.primary.main};
        box-shadow: 10px 10px 5px #aaaaaa;
        cursor:pointer; */
        
        .options{
          padding-left: 0 !important;
          text-align: center;
          list-style-type: none;
          position:absolute;
          bottom:70px;
          width: 100%;
          left: 0;
          display:flex;
          flex-direction: column;
          align-items: center;
          justify-content:space-around;
  
          li{
            display:flex;
            justify-content:center;
            padding:5px;
            
            a{
              text-decoration: none;
              color:#FFF;
              padding:12px 15px;
  
              .btn-label{
                padding:12px 15px;
                margin-right:10px;
                width: 100%;
                white-space: nowrap;
                align-self: center;
                user-select:none;
                box-shadow: 5px 5px 5px #aaaaaa; 
                background-color: #FFF;
                color: #A1A1A1;
                border-radius: 3px;
                border: 1px solid #A1A1A1;
              }
            }
          }
        }
        
        .button{
          margin: auto;
          button{
            background: var(--telembro-laranja, #F25C05);
            color: #FFF;
            box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
            height: 52px;
            border: 2px solid #FFF;
            border-radius: 10px !important;
            outline: none;
            padding: 0 16px;
            font-size: 16px;
            transition: background all 0.2s ease-in;
            cursor: pointer;
          }
        }
      }
    }
  }

  .week-day{
    &.cem{
      max-width: 100% !important;
    }

    &.66{
      max-width: 66% !important;
    }

    &.quarentaeoito{
      max-width: 48% !important;
    }

    &.trinta{
      max-width: 31% !important;
    }
  }

  .messages-date{
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;

    display: flex;
    width: 105px;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #F2F2F2;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
  }

  .toggle-switch-container{
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    color: #011F26;
    padding: 12px 12px;
    border-radius: 8px;
    font-weight: bold;
    width: 100%;
    // max-width: 360px;
    margin: auto;
    height: 65px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
    // margin-bottom: 38px;

    .date-part{
      margin: auto;
      margin-left: 0;

      .month-year-picker {
        position: relative;
        width: 100%; /* Ajuste conforme necessário */
        // margin: 0 auto;
        // font-family: Arial, sans-serif;
        margin: auto;
        margin-left: 0;
      }

      .picker-display {
        display: flex;
        gap: 9px;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        background-color: #FFF;
        border-radius: 25px;
        cursor: pointer;
        user-select: none;
        transition: background-color 0.3s ease;
      }

      .picker-display:hover {
        background-color: #FFF;
      }

      .picker-display:focus {
        background-color: #FFF;
      }

      .month {
        color: #F25B08;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .year {
        color: #BFBFBF;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .arrow {
        border: solid #F25B08;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transition: transform 0.3s ease;
      }

      .arrow.down {
        transform: rotate(45deg);
      }

      .arrow.up {
        transform: rotate(-135deg);
      }

      .picker-dropdown {
        position: absolute;
        top: 40px; /* Altura do picker-display + margem */
        left: 0;
        // width: 100%;
        max-height: 300px; /* Altura moderada com scroll */
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        z-index: 1000;
      }

      .year-section {
        padding: 10px 15px;
        border-bottom: 1px solid #eee;
      }

      .year-label {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
        color: #555;
      }

      .months-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 3 colunas */
        gap: 10px;
      }

      .month-option {
        padding: 5px 10px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.2s ease, color 0.2s ease;
        font-size: 14px;
        color: #333;
        font-weight: normal;
      }

      .month-option:hover {
        background-color: #f0f0f0;
      }

      .month-option.selected {
        background-color: #F25C05;
        color: #fff;
      }

      .month-option:focus {
        outline: 2px solid #F25C05;
        // background: rgba(242, 91, 8, 0.10);
        background: #fff;
      }

      @media (max-width: 500px) {
        // .month-year-picker {
        //     width: 90%;
        // }

        .months-grid {
            grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    /* src/components/ToggleSwitch/ToggleSwitch.css */

    /* src/components/ToggleOption/ToggleOption.css */

    .toggle-option {
      position: relative;
      // width: 120px; /* Ajuste conforme necessário */
      // height: 50px; /* Ajuste conforme necessário */
      width: 88px; /* Ajuste conforme necessário */
      height: 40px; /* Ajuste conforme necessário */
      background-color: #ccc;
      border-radius: 25px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px;

      &:hover {
        background-color: #b3b3b3;
      }

      &.toggle-on {
        background: rgba(242, 91, 8, 0.10);
      }

      &.toggle-off {
        background: rgba(242, 91, 8, 0.10);
      }

        /* Ícones fixos nas extremidades */
      .toggle-icon {
        width: 24px; /* Ajuste conforme necessário */
        height: 24px; /* Ajuste conforme necessário */
        margin: auto;
      }

      // .toggle-icon-left {
      //   /* Posicionamento opcional adicional */
      // }

      // .toggle-icon-right {
      //   /* Posicionamento opcional adicional */
      // }

      /* Círculo do toggle */
      .toggle-circle {
        position: absolute;
        top: 4px;
        left: 5px;
        width: 33px; /* Ajuste conforme necessário */
        height: 33px; /* Ajuste conforme necessário */
        background-color: #F25B08;
        border-radius: 50%;
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 5px rgba(0,0,0,0.3);
      }

      /* Movimentação do círculo */
      &.toggle-on {
        .toggle-circle {
          transform: translateX(42px); /* Ajuste para mover o círculo para a direita */
        }
      }

      &.toggle-off{
        .toggle-circle {
          transform: translateX(3px);
        }
      }

      /* Ícone dentro do círculo */
      .toggle-circle-icon {
        width: 24px; /* Ajuste conforme necessário */
        height: 24px; /* Ajuste conforme necessário */
      }
    }
  }

  .week-names-row-container{
    padding: 8px 0px;

    .week-names-row{
      display: flex;
      justify-content: space-between;
      color: #F25C05;
      background: transparent;
      border: none;
      font-weight: bold;
  
      .week-name{
        background: #FFF;
        border-radius: 12px;
        padding: 4px 8px;
        box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
        font-size: 12px;
        width: 48px;
        text-align: center;
        margin-left: 2px;
        margin-right: 2px;
        color: #D9D9D9;
        font-family: Raleway;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
      }
    }
  }

  .navigation-buttons-container{
    display: flex;
    justify-content: space-between;
    // padding-top: 16px;
    margin-bottom: 8px;
    border-radius: 8px;
    background: #FFF;
    color: #999;

    .button-container{
      .button{
        &.navigation{
          border: none;
          color: #999;
          background: #FFF;
          box-shadow: none;
          font-size: 14px;
          white-space: nowrap;

          &.current-month-name{
            color: #F25C05;
            font-size: 16px;
          }
        }
      }
    }
  }

  .week-rows-container{
    // margin-bottom: 86px;

    .week-row-container{
      margin-top: 4px;
      margin-bottom: 4px;

      .week-days-container{
        display: flex;
        justify-content: space-between;
        padding: 12px 0px;
        border-radius: 8px;

        .null-day{
          background: transparent;
          margin-right: 2px;
          margin-left: 2px;
          width: 41px;
          min-width: 41px;
          max-width: 42px;
          height: auto;
        }

        .week-day{
          cursor: pointer !important;
          border-radius: 50%;
          // box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
          border: 1.5px solid #D9D9D9;
          text-align: center;
          padding-top: 10px;
          
          color: #F25B08;
          text-align: center;
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          // width: 48px;
          // height: 48px;
          margin-right: 2px;
          margin-left: 2px;
          // padding: 10px 16px;
          width: 41px;
          min-width: 41px;
          max-width: 42px;
          padding: 10px 8px 10px 8px;
          
          &.100{
            max-width: 100% !important;
          }

          &.66{
            max-width: 66% !important;
          }

          &.50{
            max-width: 100% !important;
          }

          &.48{
            max-width: 48% !important;
          }

          &.30{
            max-width: 31% !important;
          }

          &.selected-date{
            border: 1.5px solid transparent !important;
            background: rgba(242, 91, 8, 0.40);
            color: #F25C05;
          }

          &.has-appointments{
            border: 1.5px solid #3E527E;
            background: #3E527E;
            color: #FFF;

            &.today{
              border: 1.5px solid #3E527E;
              background: #F25C05;
              color: #FFF;
            }

            &.selected-date{
              border: 1.5px solid #3E527E !important;
              background: rgba(242, 91, 8, 0.40);
              color: #F25C05;
            }
          }
          
          &.today{
            border: 1.5px solid #F25C05;
            background: #F25C05;
            color: #FFF;
          }

          .appointments-list{
            padding-top: 24px;
            padding-left: 8px;
            padding-bottom: 12px;
            text-align: left;
            font-weight: normal;
            font-size: 14px;
            width: 100%;
            overflow-x: hidden;

            .appointment{
              font-size: 8px;
              width: 92%;
              overflow-x: hidden;
              white-space: nowrap;
            }

            &.active{
              padding-top: 24px;
              padding-left: 24px;
              padding-bottom: 12px;
              text-align: left;
              font-weight: normal;
              font-size: 14px;
              width: 100%;
              overflow-x: hidden;

              .appointment{
                font-size: 14px;
              }
            }
          }

          .remaining-appointments{
            text-align: right;
            font-weight: normal;
            font-size: 4px;

            a{
              color: #FFF;
            }
          }

          .number{
            color: #FFF;
            background: #F25C05;
            border-radius: 8px;
            box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
            border: 1px solid #F25C05;
            width: 48px;
            height: 48px;
            text-align: center;
            padding-top: 10px;
            margin: auto;
            margin-left: 0;
            flex-shrink: 0;
          }
        }

        &.active{
          border: none;
          padding: 0;
          background: transparent;
          box-shadow: none;
          // justify-content: space-around;
          flex-direction: row;
          flex-wrap: wrap;

          .week-day{
            border-radius: 8px;
            box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
            border: none;
            width: 100%;
            max-width: 31%;
            height: 100%;
            min-height: 153px;
            cursor: pointer !important;

            color: #999;
            background: #FFF;
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 6px;
            margin-top: 6px;
            margin-left: 2px;
            margin-right: 2px;
            text-align: left;
            padding: 12px;

            .remaining-appointments{
              text-align: right;
              font-weight: normal;
              font-size: 12px;

              a{
                color: #FFF;
              }
            }

            &.active{
              max-width: 100%;
              // height: 170px;
              height: 117px;
              border-left: 8px solid #F25C05;
              display: flex;
            }
          }
        }
      }
    }
  }

  table{
    width: 100%;
    tr{
      td{
  	    width: 100%;
      }
    }
  }

  .suggestions {
    position: absolute;
    top: 50px;
    left: 0px;
    z-index: 999;
    cursor: pointer;
    width: 100%;
    max-height: 200px;
    overflow: auto;
  }
  
  .suggestions > div {
    padding: 15px 25px;
    // border-bottom: 1px solid #BBB;
    border: 1px solid #BBB;
    transition: all 0.1s ease;
  }
  
  .suggestions > div:hover {
    background-color: #dbdbdb !important;
    color: #000000;
  }
  
  .suggestions > div,
  .form-control-auto input {
    // font-size: 14px !important;
    background-color: #FFF;
    color: #000000;
    // color: #ffffff;
  }

  .file-input-container {
    position: relative;
    display: inline-block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .custom-file-label {
    display: flex;
    width: 100%;
    // width: 328px;
    height: 56px;
    min-height: 56px;
    padding: 8px 34px;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--primary, #F25C05);
    color: var(--primary, #F25C05);
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;

    &.selected{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      width: 100%;
      // max-width: 300px;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 10px;
      cursor: pointer;
      color: #333;

      .file-name{
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 212px;
        overflow-x: hidden;
      }
    }
  }
  
  .custom-file-label:hover {
    background-color: #f1f1f1;
  }
  
  .remove-file-btn {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .remove-file-btn:hover {
    color: red;
  }
  
  
  .document-value{
    width: 100%;
    background: #FFF;
    width: 100%;
    background: #FFF;
    min-height: 56px;
    border: none;
    height: 56px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    outline: none;
    padding: 25px 16px 9px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #636668 !important;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    -webkit-letter-spacing: -0.36px;
    -moz-letter-spacing: -0.36px;
    -ms-letter-spacing: -0.36px;
    letter-spacing: -0.36px;

    &:focus{
      border: 1px solid #F25C05;
    }
  }

  input[type='checkbox']{
    width: 18px;
    height: 18px;
    accent-color: #F25C05;
  }

  .modal-container{
    background: #FFF;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 40px;

    &.full{
      height: 100vh;
    }

    box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
    max-width: 400px;
    width: 100%;
    z-index: 999;
    position: fixed;
    bottom: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: var(--telembro-preto-l, #636668);

    &.tip{
      color: var(--telembro-branco, #FFF);
      background: var(--telembro-laranja, #F25C05);
    }

    .modal-title{
      display: flex;
      justify-content: space-between;

      .text{
        // width: 249px;
        height: 60px;

        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.36px;

        &.danger{
          color: red;
        }
      }

      .close-button{
        height: 32px;
        width: 32px;
        button{
          background: transparent !important;
          border: none;
          img{
            margin-top: -5px;
          }
        }
      }
    }

    .modal-body{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
      margin-bottom: 8px;

      .center-image{
        width: 100%;
        text-align: center;
        margin-bottom: 24px;

        img{
          margin: auto;
        }
      }

      .modal-body-title{
        color: var(--dark, #011F26);
        font-family: Raleway;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 28.8px */
        margin-bottom: 16px;
      }
      
      .modal-body-text{
        color: var(--dark, #011F26);
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // margin-bottom: 16px;
        width: 100%;
        max-height: 250px;
        overflow-y: auto;
        
        span{
          color: var(--dark, #E1672A);
        }
      }

      &.full{
        // height: 70vh;
        height: 80%;

        a.button{
          // margin-bottom: 16px;
          // width: 100%;
          // max-width: 320px;

          background: var(--telembro-laranja, #F25C05);
          color: #FFF;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
          height: 40px;
          border: 1px solid var(--telembro-laranja, #F25C05);
          border-radius: 10px !important;
          outline: none;
          font-weight: 700;
          padding: 8px 16px;
          font-size: 16px;
          cursor: pointer;
          margin: auto;

          svg{
            margin-right: 8px;
          }
        }

        .terms-content{
          margin-top: 32px;
          // height: 60vh;
          height: 90%;
          overflow: auto;
          padding-right: 10px;
          padding-left: 10px;
          // padding: 10px;
        }
      }

      .tag-item {
        background-color: #d4d5d6;
        display: inline-block;
        font-size: 14px;
        border-radius: 30px;
        height: 30px;
        padding: 0 4px 0 1rem;
        display: inline-flex;
        align-items: center;
        margin: 0 0.3rem 0.3rem 0;

        .button {
          background-color: white;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          border: none;
          cursor: pointer;
          font: inherit;
          margin-left: 10px;
          font-weight: bold;
          padding: 0;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
        }
      }
    }

    .button{
      margin-top: 16px !important;
    }
  }

  .floating-button-container{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 19px;
    z-index: 1;
  }

  .info-page{
    margin: auto;
    padding-bottom: 20px;

    .image-header{
      padding-top: 37px;
      margin-bottom: 20px;
      text-align: center;
  	  
      // &.talktous{
      //   padding-top: 128px;
      // }

      img{
        width: 100%;
      }

      &.icon{
        padding-top: 26px;
        margin-bottom: 16px;

        img{
          width: 64px;
          height: 64px;
        }
      }
    }

    .text-header{
      color: var(--telembro-azul-d, #A1A1A1);
      text-align: center;
      width: 100%;
      max-width: 320px;
      /* TL-tutorial-tela */
      font-family: Raleway;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.36px;
      margin: auto;
      margin-bottom: 20px;
    }

    .big-text-header{
      color: var(--telembro-cinza-3, #666);

      /* TL-tutorial-tela */
      padding-top: 5px;
      font-family: Raleway;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.36px;
      width: 100%;
      max-width: 320px;
      margin: auto;
      margin-bottom: 22px;
      min-height: 44px;
    }

    .big-text{
      color: var(--telembro-cinza-4, #333);

      /* TL-modulo-privacidade-texto */
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: -0.24px;
      width: 100%;
      max-width: 320px;
      margin: auto;
      margin-bottom: 10px;

      .important{
        line-height: normal;
        font-weight: bold;
      }
    }

    .info-card{
      border-radius: 4px;
      border: 1px solid var(--telembro-cinza-bordas, #E8E8E8);
      background: var(--telembro-branco, #FFF);
      padding-top: 15px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
      margin-bottom: 20px;

      
      .info-card-header{
        color: var(--telembro-azul-d, #A1A1A1);
        margin-bottom: 6px;

        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
        display: flex;

        .image{
          margin: auto;
          margin-left: 0;
          margin-right: 0;

          img{
            width: 24px;
            height: 24px;
          }
        }
        .title{
          margin-left: 6px;
          width: 100%;
          max-width: 250px;
          min-height: 37px;
        }
      }

      .info-card-content{
        color: var(--telembro-cinza-2, #999);

        /* TL-modulo-privacidade-texto */
        font-family: Raleway;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        letter-spacing: -0.24px;
        margin-bottom: 5px;
      }

      .info-card-link{
        a{
          color: var(--telembro-laranja, #F25C05);

          /* TL-modulo-privacidade-texto */
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 166.667% */
          letter-spacing: -0.24px;
          text-decoration: none;
          min-height: 20px;
        }
      }

      .info-card-footer{
        color: var(--telembro-cinza-1, #BBB);
        text-align: right;
        min-height: 20px;
        
        /* TL-modulo-privacidade-data */
        font-family: Raleway;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }

    .button-container{
      padding-top: 10px;
      // margin-bottom: 40px;
      margin-bottom: 20px;
    }
  }

  form{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .anchor-container{
    text-align: left;
    padding-top: 14px;
    font-weight: normal;
    color: #000;

    a{
      font-size: 14px;
      color: #F25C05;
      text-decoration: underline;
      font-weight: bold;
  }
  }

  .small-legal-disclaimer{
    padding-top: 24px;
    padding-bottom: 14px;
    color: #A1A1A1 !important;
    font-size: 12px !important;
    width: 100%;
    text-align: left;
    font-weight: normal !important;

    a{
      color: #F25C05 !important;
      font-weight: bold !important;
    }
  }

  .small-disclaimer{
    // padding-top: 24px;
    // padding-bottom: 14px;
    margin-top: -4px;
    color: #A1A1A1 !important;
    font-size: 12px !important;
    width: 100%;
    text-align: left;
    font-weight: normal !important;
  }

  .form-label{
    position: absolute;
    top: 6px;
    left: 16px;
    color: #F25B08 !important;
    font-size: 10px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
    z-index: 1;

    span{
      color: #A1A1A1;
    }

    &.counter{
      left: 105px;

      @media (max-width: 767px) {
        left: 83.5px;
      }
    }

    &.not-input{
      left: 0px;
    }
  }

  .colors-container{
    display: flex;
    flex-direction: column;
    gap: 16px;

    .colors-wall{
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      align-items: center;
      justify-content: center;
  
      .color-item{
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        width: 58px;
        height: 30px;
      }
    }
  }

  .fake-select{
    position: relative;
    // z-index: 10;

    .color-item{
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      width: 58px;
      height: 30px;

      &.selected{
        width: 270px;
        height: 15px;
      }
    }

    img{
      position: absolute;
      right: 11px;
      top: 16px;
    }

    cursor: pointer;

    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
    min-height: 56px;
    height: 56px;
    border: 1px solid #CCC;
    border-radius: 8px !important;
    border-radius: 4px 4px 0px 0px;
    outline: none;
    padding: 25px 16px 9px 16px;
    /* appearance: none; */
    color: #636668 !important;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.36px;
  }

  .fixed-bottom-button{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
    // width: 360px;
    width: 100%;
    height: 96px;
    flex-shrink: 0;
    padding: 16px;
    display: flex;
    align-items: center;

    text-align: center;

    .button-container{
      width: 100%;
    }
  }

  .button-container{
      text-align: center;
      margin-top: 24px;

      &.vertical-buttons{
        position: absolute;
        bottom: 20px;
      }

      .button{
        width: 100%;
        background: var(--telembro-laranja, #F25C05);
        color: #FFF;
        box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
        height: 56px;
        border: 1px solid var(--telembro-laranja, #F25C05);
        border-radius: 8px !important;
        outline: none;
        font-weight: 700;
        padding: 0 16px;
        font-size: 16px;
        cursor: pointer;
        margin: auto;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;

        &:hover{
          background: #FF5A00;
        }

        &.white{
          background: #FFF;
          border: 1px solid #CCC;
          color: #000;
        }

        &[disabled]{
          background: #CCC;
          border: 1px solid #CCC;
          cursor: default;
        }

        &:disabled{
          background: #CCC;
          border: 1px solid #CCC;
          cursor: default;
        }

        &.danger{
          border: 1px solid red;
          background: red;
          color: #FFF;
        }

        &.secondary{
          border: 1px solid var(--telembro-laranja, #F25C05);
          background: var(--telembro-laranja-l, #FFF1E9);
          color: var(--telembro-laranja, #F25C05);
        }

        &.cancel{
          border: 1px solid var(--telembro-laranja, #F25C05);
          background: var(--telembro-laranja-l, #FFFFFF);

          color: #A1A1A1;
        }
      }
    }

  .input-file-container{
    display: flex;
    justify-content: space-between;
    // gap: 16px;

    button{
      max-width: 278px !important;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
  
      & + label {
        font-size: 1.25em;
        font-weight: 700;
        color: white;
        background-color: black;
        display: inline-block;
      }
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: red;
    }
  }

  .bottom-space{
    height: 40px;
    width: 100%;
  }

  .sidenav {
    height: 100%; /* 100% Full-height */
    width: 100%; /* 0 width - change this with JavaScript */
    // max-width: 400px;
    max-width: 295px;
    margin: auto;
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    padding-left: 10px;
    background: var(--telembro-laranja, #F25C05);
    // overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    color: #FFFFFF;

    button{
      background: transparent;
      border: none;
    }

    .close-button-container{
      height: 48px;
      width: 48px;
      padding-top: 5px;
      
      .closebtn {
        margin: auto;
        top: 5px;
        left: 8px;
      }
    }

    .name-container{
      padding-top: 17px;
      padding-left: 10px;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      max-width: 224px;

      div{
        margin-left: 0;

        &.name{
          margin-left: 0;
          margin: auto;
        }

        color: var(--telembro-branco, #FFF);

        /* TL-usuario-menu */
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 110%; /* 19.8px */
      }
    }

    .footer-container{
      position: fixed;
      left: 5px;
      bottom: 2%;
      width: 100%;
      height: 20px;
      max-width: 295px;
      text-align: center;

      @media (max-width: 767px) {
        bottom: 27px;
      }

      @media (min-width: 767px) {
        bottom: 5%;
      }
    }

    .social-container{
      position: fixed;
      left: 315px;
      bottom: 255px;
      width: 100%;
      height: 20px;
      max-width: 295px;
      text-align: left;
      z-index: 9999999;
      display: grid;

      a{
        margin-bottom: 30px;
      }
    }

    .links-container{
      padding-top: 46px;
      padding-left: 28px;
      width: 100%;
      max-width: 238px;
      height: 72vh;
      overflow: auto;

      a {
        text-decoration: none;
        font-size: 20px;
        color: #FFFFFF;
        display: block;
        transition: 0.3s;
        height: 33px;
        // margin-bottom: 20px;
        // margin-bottom: 49px;
        margin-bottom: 14px;

        // @media (max-width: 767px) {
        //   margin-bottom: 19px;
        // }
        
        &:hover {
          color: #818181;
        }
      }

      button{
        color: var(--telembro-laranja-d, #9D430F);
        padding: 0;
        /* TL-menu-sair */
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 200%; /* 36px */
        font-size: 22px;
        display: block;
        transition: 0.3s;
        height: 49px;
        margin-bottom: 20px;
      }
    }
  }

  .new-sidenav {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background: #FFF;

    button{
      background: transparent;
      border: none;

      img{
        height: 24px;
      }
    }

    .close-button-container{
      padding: 16px;
      height: 24px;
      width: 100%;
    }

    .name-container{
      padding-top: 40px;
      padding-left: 19px;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      max-width: 224px;
      margin-bottom: 43px;

      div{
        margin-left: 0;

        img{
          width: 70px;
          height: 70px;
          flex-shrink: 0;
          border-radius: 70px;
        }

        &.name{
          margin-left: 0;
          margin: auto;
          color: #000;
          font-family: Raleway;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .menu-content{
      height: 100vh;
      overflow-y: auto;
      padding-bottom: 290px;

      .menu-options-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        // margin-bottom: 165px;
        margin-bottom: 32px;
        // margin: auto;
  
        .menu-option{
          padding: 20px 16px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-bottom: 1px solid #BDBDBD;
          width: 100%;
          gap: 16px;
          color: var(--primary, #F25C05);
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          cursor: pointer;
  
          &.version{
            padding: 20px 19px;
            border-bottom: none;
          }
  
          .arrow{
            margin: auto;
            margin-right: 0;
          }
        }
  
        .submenu{
            padding-left: 56px;
            width: 100%;
  
          .submenu-item{
            padding: 20px 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid #BDBDBD;
            width: 100%;
            gap: 16px;
            color: var(--primary, #F25C05);
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
    
            &.version{
              padding: 20px 19px;
              border-bottom: none;
            }
    
            .arrow{
              margin: auto;
              margin-right: 0;
            }
          }
        }
      }
  
      .footer-container{
        width: 100%;
        text-align: center;
        margin-bottom: 35px;

        img{
          width: 100%;
          max-width: 105px;
          height: 27px;
          flex-shrink: 0;
        }
      }
  
      .social-container{
        width: 100%;
        max-width: 205px;
        text-align: left;
        padding: 0px 0px;
        display: flex;
        justify-content: space-between;
        margin: auto;
  
        // a{
        //   // margin-bottom: 30px;
        //   // border: 1px solid #F25C05;
        //   // border-radius: 70px;
        //   // padding: 12px 14px;
        //   // width: 100%;
        //   // gap: 16px;
        //   // color: var(--primary, #F25C05);
        // }
      }
    }

    .links-container{
      padding-top: 46px;
      padding-left: 28px;
      width: 100%;
      max-width: 238px;
      height: 72vh;
      overflow: auto;

      a {
        text-decoration: none;
        transition: 0.3s;
        height: 33px;
        color: var(--primary, #F25C05);
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 14px;

        // @media (max-width: 767px) {
        //   margin-bottom: 19px;
        // }
        
        &:hover {
          color: #818181;
        }
      }

      button{
        color: var(--telembro-laranja-d, #9D430F);
        padding: 0;
        /* TL-menu-sair */
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 200%; /* 36px */
        font-size: 22px;
        display: block;
        transition: 0.3s;
        height: 49px;
        margin-bottom: 20px;
      }
    }
  }
  
  .big-logo-container{
    padding-top: 115px;
    text-align: center;
    margin-bottom: 80px;
    
    &.smaller{
      padding-top: 40px;
      margin-bottom: 40px;
    }
  }

  .no-results-image-container{
    text-align: center;
    margin-bottom: 52px;

    .image{
      margin-bottom: 20px;
      
      img{
        width: 100% !important;
      }
    }

    .text{
      color: var(--telembro-azul-d, #A1A1A1);
      text-align: center;

      /* TL-tutorial-tela */
      font-family: Raleway;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.36px;
    }
  }

  .menu-bar-container{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    .menu-bar{
      height: 54px;
      background: #FFF;
      box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
      width: 100%;
      margin: auto;
      top: 0;
      left: 0;
      padding-left: px;
      padding-right: 16px;
      flex-shrink: 0;  
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      color: #000;
      gap: 8px;
      
      button{
        background: transparent;
        border: none;
      }

      .menu-button-container{
        height: 48px;
        width: 48px;
        margin: auto;
        margin-left: 0;
        margin-right: 0;

        &.back{
          padding-top: 10px;
          padding-left: 8px;
        }

      }
      
      .options-button-container{
        padding-top: 10px;
        max-width: 48px;
        height: 48px;
        margin: auto;
        margin-left: 0;
        margin-right: 0;
      }

      .page-title{
        // color: var(--telembro-branco, #FFF);
        color: #000;
        text-align: left;
        /* TL-titulo-tela */
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.36px;
        margin: auto;
        margin-left: 0;
      }
    }  
  }

  .react-datepicker-popper{
    z-index: 2 !important;
    .react-datepicker{
      margin-bottom: 0px !important;
  
      span{
        margin-right: 0 !important;
      }
  
      .react-datepicker__header {
        .react-datepicker__current-month{
          margin-top: 0 !important;
        }
      }
  
      .react-datepicker__week{
        color: var(--telembro-preto-l, #636668) !important;
        // text-align: center;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 300 !important;
        line-height: normal !important;
        letter-spacing: 1.56px !important;
    
        .react-datepicker__day--selected{
          border-radius: 7px;
          background: var(--telembro-laranja, #F25C05) !important;
          color: #FFF !important;
        }
      }
    }
  }

  // Mobile Toggle Switch
  .toggleWrapper {
    // margin: auto;
    // padding: 20px;
    // width: 55px;
    // border: 1px solid lightgray;
    // margin-top: 20px;
    // border-radius: 5px;
    // background: white;
    input {
      &.mobileToggle {
        opacity: 0; // hides checkbox
        position: absolute;
        & + label {
          cursor: pointer;
          position: relative;
          display: inline-block;
          user-select: none;
          // -moz-transition: .4s ease;
          // -o-transition: .4s ease;
          // -webkit-transition: .4s ease;
          // transition: .4s ease;
          -webkit-tap-highlight-color: transparent;
          height: 32px;
          width: 58px;
          // border: 2px solid #E8E8E8;
          border-radius: 60px;
          &:before {
            content: "";
            position: absolute;
            display: block;            
            -moz-transition: .2s cubic-bezier(.24, 0, .5, 1);
            -o-transition: .2s cubic-bezier(.24, 0, .5, 1);
            -webkit-transition: .2s cubic-bezier(.24, 0, .5, 1);
            transition: .2s cubic-bezier(.24, 0, .5, 1);

            background: #FFFFFF; // Active Color
            height: 32px;
            width: 58px;
            top: 0;
            left: 0;
            border-radius: 30px;
          }
          &:after {
            content: "";
            position: absolute;
            display: block;

            box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, .04), 0 4px 9px hsla(0, 0%, 0%, .13), 0 3px 3px hsla(0, 0%, 0%, .05);
            -moz-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
            -o-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
            -webkit-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
            transition: .35s cubic-bezier(.54, 1.60, .5, 1);
            background: #BBBBBB;
            height: 23px;
            width: 23px;
            top: 4px;
            left: 5px;
            border-radius: 60px;
          }
        }

        // When Active
        &:checked {
          & + label:before {
            background: #BBBBBB; // Active Color
            -moz-transition: width .2s cubic-bezier(0, 0, 0, .1);
            -o-transition: width .2s cubic-bezier(0, 0, 0, .1);
            -webkit-transition: width .2s cubic-bezier(0, 0, 0, .1);
            transition: width .2s cubic-bezier(0, 0, 0, .1);
          }
          & + label:after {
            left: 31px;
            background: #F25C05;
          }
        }
      }
    }
  }

  // Mobile Toggle Switch
  .toggleWrapper2 {
    // margin: auto;
    // padding: 20px;
    // width: 55px;
    // border: 1px solid lightgray;
    // margin-top: 20px;
    // border-radius: 5px;
    // background: white;
    input {
      &.mobileToggle {
        opacity: 0; // hides checkbox
        position: absolute;
        & + label {
          cursor: pointer;
          position: relative;
          display: inline-block;
          user-select: none;
          // -moz-transition: .4s ease;
          // -o-transition: .4s ease;
          // -webkit-transition: .4s ease;
          // transition: .4s ease;
          -webkit-tap-highlight-color: transparent;
          height: 32px;
          width: 58px;
          // border: 2px solid #E8E8E8;
          border-radius: 60px;
          &:before {
            content: "";
            position: absolute;
            display: block;            
            -moz-transition: .2s cubic-bezier(.24, 0, .5, 1);
            -o-transition: .2s cubic-bezier(.24, 0, .5, 1);
            -webkit-transition: .2s cubic-bezier(.24, 0, .5, 1);
            transition: .2s cubic-bezier(.24, 0, .5, 1);

            background: #F6F6F6; // Active Color
            height: 32px;
            width: 58px;
            top: 0;
            left: 0;
            border-radius: 30px;
          }
          &:after {
            content: "";
            position: absolute;
            display: block;

            box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, .04), 0 4px 9px hsla(0, 0%, 0%, .13), 0 3px 3px hsla(0, 0%, 0%, .05);
            -moz-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
            -o-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
            -webkit-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
            transition: .35s cubic-bezier(.54, 1.60, .5, 1);
            background: #F25C05;
            height: 23px;
            width: 23px;
            top: 4px;
            left: 5px;
            border-radius: 60px;
          }
        }

        // When Active
        &:checked {
          & + label:before {
            background: #F6F6F6; // Active Color
            -moz-transition: width .2s cubic-bezier(0, 0, 0, .1);
            -o-transition: width .2s cubic-bezier(0, 0, 0, .1);
            -webkit-transition: width .2s cubic-bezier(0, 0, 0, .1);
            transition: width .2s cubic-bezier(0, 0, 0, .1);
          }
          & + label:after {
            left: 31px;
            background: #F25C05;
          }
        }
      }
    }
  }

  // centering
  // body {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   height: 100vh;
  // }

  .input-group-btn{
    position: relative;

    input{
      padding-right: 36px;
    }

    button{
      position: absolute;
      top: 8px;
      right: 0;
      outline: none !important;
      border-radius: 50%;
    }
  }

  // .navbar{
  //   .navbar-brand{
  //     img{
  //       // border-radius: 50%;
  //       height: 48px;
  //     }
  //   }

  //   .menu-items{
  //     display: flex;
  //     align-items: self-start;
  //     width: 100%;
  //   }

  //   .navbar-nav {
  //     display: flex;
  //     align-items: self-end;
  //     justify-content: flex-end;
  //     width: 100%;

  //     @media (max-width: 767px) {
  //       align-items: start;
  //       justify-content: flex-start;
  //     }
  //   }
  // }

  .splashscreen-overlay {

    img{
      position: absolute;
      width: auto;
      height: 115%;
      left: 34%;
      top: -10%;
      z-index: 9999;
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        width: 100%;
        height: 115%;
        left: 0;
        top: -10%;
        z-index: 9999;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
